import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import './assets/scss/app.scss'
import Nav from './components/nav/nav'
import Footer from './components/layout/footer'

const layout = ({ children, title }) => (
  <main>
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteTitle
            }
          }
        }
      `}
      render={data => (
        <Helmet>
          {title && <title>{title} | {data.site.siteMetadata.siteTitle}</title>}
          {!title && <title>{data.site.siteMetadata.siteTitle}</title>}
          <meta charSet="utf-8" />
          <meta name="twitter:site" content="@packagedby" />
          <meta property="og:site_name" content="packagedby" />
        </Helmet>
      )}
    />

    <Nav />

    {children}

    <Footer />
  </main>
)

layout.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string
}

export default layout

