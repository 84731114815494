import styled from 'styled-components'

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
`

export default TitleContainer