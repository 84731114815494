import Image from "gatsby-image"
import styled from "styled-components"

const BackgroundImage = styled(Image)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default BackgroundImage
