import React from 'react'
import BackgroundImage from './background-image'

const BackgroundContainer = ({ header, children }) => (
  <div style={{ position: "relative", height: "350px" }}>
    <BackgroundImage
      fluid={header.childImageSharp.fluid}
      style={{
        height: "350px",
        position: `absolute`,
        zIndex: -1,
        filter: "brightness(30%)",
      }}
    />

    {children}
  </div>
);

export default BackgroundContainer