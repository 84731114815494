import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'

import IconLink from '../common/icon-link'
import Link from '../common/link'

const Footer = styled.footer`
  padding: 1.5rem 0;
`

const FooterContainer = styled(Container)`
  border-top: 1px solid #eee;
  padding-top: 1rem;
`

const FooterList = styled.ul`
  padding: 0;
  list-style: none;
`

export default () => (
  <Footer>
    <FooterContainer>
      <Row>
        <Col md={3}>
          <h4>Contact Us</h4>
          <div>
            <strong>packagedby Limited</strong><br />
            Office 39<br />
            Gateshead International Business Centre<br />
            Mulgrave Terrace<br />
            Gateshead<br />
            NE8 1AN<br /><br />
            Company No: 11960021<br />
            VAT No: 321073548
          </div>
        </Col>
        <Col md={3}>
          <h4>Services</h4>

          <FooterList>
            <li><Link href="/services/integration">API Integration</Link></li>
            <li><Link href="/services/software-as-a-service">SaaS</Link></li>
            <li><Link href="/services/api-development">API Development</Link></li>
            <li><Link href="/services/legacy">Legacy Applications</Link></li>
            <li><Link href="/services/serverless">Serverless</Link></li>
            <li><Link href="/services/front-end-development">UI Development</Link></li>
          </FooterList>
        </Col>
        <Col md={3}>
          <h4>Development</h4>

          <FooterList>
            <li><Link href="/development/php-development">PHP</Link></li>
            <li><Link href="/development/laravel-development">Laravel</Link></li>
            <li><Link href="/development/symfony-development">Symfony</Link></li>
            <li><Link href="/development/react-development">React</Link></li>
            <li><Link href="/development/vue-development">Vue</Link></li>
          </FooterList>
        </Col>
        <Col md={3}>
          <h4>Social</h4>

          <IconLink href="http://twitter.com/packagedby" icon={['fab', 'twitter']}></IconLink>
          <IconLink href="https://www.linkedin.com/company/packagedby" icon={['fab', 'linkedin']}></IconLink>
          <IconLink href="http://instagram.com/packagedby" icon={['fab', 'instagram']}></IconLink>
        </Col>
      </Row>
    </FooterContainer>
  </Footer>
)