import React, { Fragment } from 'react'
import isUrl from 'is-url'
import { Link } from 'gatsby'

export default (props) => {
  return (
    <Fragment>
      {isUrl(props.href) &&
        <a {...props}>
          {props.children}
        </a>
      }
      {!isUrl(props.href) &&
        <Link to={props.href} {...props}>
          {props.children}
        </Link>
      }
    </Fragment>
  )
}