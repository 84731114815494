import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Icon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: #2c3237;
  margin-right: 1rem;
`

export default Icon
