import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import styled from 'styled-components'

import LogoPath from '../../assets/images/logo.svg'

import DropdownItem from './dropdown-item'
import NavLink from './nav-link'
import NavbarBrand from './navbar-brand'

const Logo = styled.img`
  height: 3rem;
`

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <Logo src={LogoPath} alt="packagedby" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/process">Process</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Services
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/services/software-as-a-service">
                    Software as a Service
                  </DropdownItem>
                  <DropdownItem href="/services/integration">
                    API Integration
                  </DropdownItem>
                  <DropdownItem href="/services/api-development">
                    API Development
                  </DropdownItem>
                  <DropdownItem href="/services/legacy">
                    Legacy Applications
                  </DropdownItem>
                  <DropdownItem href="/services/aws-cloud-architecture">
                    AWS Cloud Architecture
                  </DropdownItem>
                  <DropdownItem href="/services/serverless">
                    Serverless
                  </DropdownItem>
                  <DropdownItem href="/services/front-end-development">
                    Front End Development
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/contact">Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
